@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  h1 {
    @apply text-4xl font-bold;
  }
  h2 {
    @apply text-3xl font-bold;
  }
  h3 {
    @apply text-2xl font-semibold;
  }
  h4 {
    @apply text-xl font-semibold;
  }
  h5 {
    @apply text-lg font-medium;
  }
  h6 {
    @apply text-base font-medium;
  }
}
@layer components {
  .no-arrows::-webkit-outer-spin-button,
  .no-arrows::-webkit-inner-spin-button {
    @apply appearance-none;
    margin: 0;
  }

  .no-arrows {
    -moz-appearance: textfield;
  }
}



body {
  margin: 0;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 2em;
}

p{
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
